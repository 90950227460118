.blog_image_skelton{
    border-radius: 15px !important;
    overflow: hidden !important;
    width: 100% !important;
    max-height: 40rem !important;
}

@media screen and (max-width: 768px) {
    .blog_image_skelton{
        width: 100% !important;
        max-height: 20rem !important;
    }
  }

.blog_description h1, .blog_description h2, .blog_description h3, .blog_description h4, .blog_description h5, .blog_description h6{
    font-size: unset;
    line-height: unset;
    margin-bottom: unset;
}
/* .blog_description p{
    font-weight: unset;
    margin-bottom: unset;
} */