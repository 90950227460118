.product_skelton_image{
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    /* padding: 2rem; */
    height: 200px;
    width: 95% !important;
}

.product_skelton_title{
    height: 2rem;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
    width: 90% !important;
}

.product_skelton_price{
    top: 14%;
    height: 1.3rem !important;
    margin-bottom: 1rem;
    width: 80% !important;
}

.product_skelton_add_btn{
    margin-bottom: 1rem;
    height: 40px;
    width: 65% !important;
}

@media screen and (max-width: 480px) {
    .product_skelton_image{
        width: 91% !important;
    }
    
  }

  @media screen and (max-width: 768px) {
    .product_skelton_image{
        width: 95.4% !important;
    }
    
  }