.tracking-detail {
    padding: 3rem 0
}

#tracking {
    margin-bottom: 1rem
}

[class*=tracking-status-] p {
    margin: 0;
    font-size: 1.1rem;
    color: #fff;
    text-transform: uppercase;
    text-align: center
}

[class*=tracking-status-] {
    padding: 1.6rem 0
}

.tracking-status-intransit {
    background-color: #65aee0
}

.tracking-status-outfordelivery {
    background-color: #f5a551
}

.tracking-status-deliveryoffice {
    background-color: #f7dc6f
}

.tracking-status-delivered {
    background-color: #4cbb87
}

.tracking-status-attemptfail {
    background-color: #b789c7
}

.tracking-status-error,
.tracking-status-exception {
    background-color: #d26759
}

.tracking-status-expired {
    background-color: #616e7d
}

.tracking-status-pending {
    background-color: #ccc
}

.tracking-status-inforeceived {
    background-color: #214977
}

.tracking-list {
    border: 1px solid #e5e5e5
}

.tracking-item {
    border-left: 1px solid #e5e5e5;
    position: relative;
    padding: 2rem 1.5rem .5rem 2.5rem;
    font-size: .9rem;
    margin-left: 3rem;
    min-height: 5rem
}

.tracking-item:last-child {
    padding-bottom: 4rem
}

.tracking-item .tracking-date {
    margin-bottom: .5rem
}

.tracking-item .tracking-date span {
    color: #888;
    font-size: 85%;
    padding-left: .4rem
}

.tracking-item .tracking-content {
    padding: .5rem .8rem;
    background-color: #f4f4f4;
    border-radius: .5rem
}

.tracking-item .tracking-content span {
    display: block;
    color: #888;
    font-size: 85%
}

.tracking-item .tracking-icon {
    line-height: 2.6rem;
    position: absolute;
    left: -1.3rem;
    width: 2.6rem;
    height: 2.6rem;
    text-align: center;
    border-radius: 50%;
    font-size: 1.1rem;
    background-color: #fff;
    color: #fff
}

.tracking-item .tracking-icon.status-sponsored {
    background-color: rgb(43, 162, 188)
}

.tracking-item .tracking-icon.status-delivered {
    background-color: #4cbb87
}

.tracking-item .tracking-icon.status-outfordelivery {
    background-color: #f5a551
}

.tracking-item .tracking-icon.status-deliveryoffice {
    background-color: #f7dc6f
}

.tracking-item .tracking-icon.status-attemptfail {
    background-color: #b789c7
}

.tracking-item .tracking-icon.status-exception {
    background-color: #d26759
}

.tracking-item .tracking-icon.status-inforeceived {
    background-color: #214977
}

.tracking-item .tracking-icon.status-intransit {
    color: #e5e5e5;
    border: 1px solid #e5e5e5;
    font-size: .6rem
}

@media(min-width:992px) {
    .tracking-item {
        margin-left: 10rem
    }

    .tracking-item .tracking-date {
        position: absolute;
        left: -10rem;
        width: 7.5rem;
        text-align: right
    }

    .tracking-item .tracking-date span {
        display: block
    }

    .tracking-item .tracking-content {
        padding: 0;
        background-color: transparent
    }
}

/*  //old ind */

.track-info h3 {
    font-size: 18px;
    color: #1B1E1A;
    margin-bottom: 30px
}

.track-info ul {
    padding: 0;
    margin: 0
}

.track-info ul li {
    position: relative;
    letter-spacing: .35px
}

.track-info ul li ul {
    width: 80%
}

.track-info ul li ul li {
    display: grid;
    grid-template-columns: 120px 1fr;
    padding: 15px 0;
    font-size: 12px;
    color: #6f6f6f;
    font-weight: 400
}

.track-info ul li ul li::before {
    top: 20px;
    left: -30px;
    position: absolute;
    width: 12px;
    height: 2px;
    background: #959595;
    content: ""
}

.track-info ul li ul li h4 {
    margin: 0
}

.track-info ul li ul li strong,
.track-info ul li ul li h4 {
    color: #1B1E1A;
    font-weight: 500
}

.track-info ul li ul li strong {
    display: inline-block;
    margin-bottom: 6px
}

.track-info ul li ul li b {
    display: block;
    color: #1B1E1A;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px
}

.track-info>ul>li {
    font-size: 16px;
    font-weight: 700;
    padding-left: 30px;
    border-left: 2px solid #959595;
}

.track-info>ul>li::before {
    top: 0 !important;
}

.track-info>ul>li:last-child::after {
    bottom: -1px
}


.track .track-info>ul>li:last-child::after,
.track .track-info>ul>li::before {
    content: "";
    height: 18px;
    width: 18px;
    background: #959595;
    border: 3px solid #fff;
    border-radius: 50%;
    position: absolute;
    left: -10px
}

.tracking_error {
    padding: 15px;
    font-weight: 500;
    margin-top: 15px;
    background: #ff2828;
    text-align: center !important;
    color: #fff !important;
    border-radius: 4px
}

.track-not-found {
    text-align: center;
    margin-top: 15px
}

.track-not-found::before {
    content: "";
    /* background: url(/wp-content/themes/ecourier-2.0/images/not-found.svg) no-repeat center center; */
    -webkit-background-size: 100%;
    -ms-background-size: 100%;
    background-size: 100%;
    width: 150px;
    height: 150px;
    display: block;
    margin: 30px auto
}

.track-not-found h3 {
    color: #1B1E1A;
    margin-bottom: 5px
}

.track-not-found h4 {
    color: #3a3a3a;
    font-weight: 400
}

.track-info>ul>li.active::before,
.track-info>ul>li.active:last-child::after {
    background: #0098b8;
}
.track-info>ul>li.active {
    border-color: #0098b8;
}

.track-info>ul>li.active::before,
.track-info>ul>li.active:last-child::after {
    background: #0098b8;
}

.track-info>ul>li.active ul li::before {
    background: #0098b8 !important;
}

.track .track-info>ul>li:last-child::after,
.track .track-info>ul>li::before {
    content: "";
    height: 18px;
    width: 18px;
    background: #0098b8;
    border: 3px solid #fff;
    border-radius: 50%;
    position: absolute;
    left: -10px;
}

.track-info>ul>li::before {
    top: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.track-info>ul>li {
    font-size: 16px;
    font-weight: 700;
    padding-left: 30px;
    border-left: 2px solid #959595;
}

.track-info ul li {
    position: relative;
    letter-spacing: .35px;
}

li {
    list-style: none;
}

li {
    text-align: -webkit-match-parent;
}

ul {
    list-style-type: disc;
}

.track-info ul li {
    position: relative;
    letter-spacing: .35px;
}

.track-content {
    padding: 30px;
    background: #fff;
    border-radius: 6px;
    padding-top: 10px;
}

.track {
    position: relative;
    z-index: 1;
}