.subscribe_div_section{
  width: 51%;
}
.padding_top_none{
  padding-top: 0 !important;
}
.subscribe_top_img{
  height: 95%;
      width: 40%;
}
@media screen and (min-width: 2200px) {
  .subscribe_top_img {
    top: 0.4rem !important;
    left: 56rem !important;
    /* height: 15rem !important; */
}
}
@media screen and (max-width: 1440px) {
  .subscribe_top_img {
    /* top: 2.4rem !important; */
    left: 40rem !important;
    /* height: 15rem !important; */
  }
  .subscribe_div_section {
    top: 26px !important;
    width: 60%;
  }
  .subscribe_bg{
    height: 20rem;;
  }
}

@media screen and (max-width: 1366px) {
  .footer_last_line{
    font-size: 0.8rem;
  }
  }

  /* On screens that are 1024px wide or less, the background color is blue */
@media screen and (max-width: 1024px) {
  .subscribe_div_section {
    top: 2.4rem !important;
  }
  .subscribe_top_img {
    top: 0.33rem !important;
    left: 35rem !important;
    height: 93.5% !important;
}
  .subscribe_bg{
    height: 15rem;
  }
  .form-subscribe {
    max-width: 400px;
  }
  }
  
  /* On screens that are 768px wide or less, the background color is olive */
  @media screen and (max-width: 768px) {
    .subscribe_div_section {
        top: 15px !important;
      }

    .subscribe_subtext{
        max-width: 15rem;
        font-size: small !important;
        /* top: -105px !important; */

    }
    .subscribe_top_img {
      top: 0.335rem !important;
      left: 27rem !important;
      height: 93.5% !important;
    }
    .form-subscribe {
      max-width: 250px;
    }
  }

  /* On screens that are 600px wide or less, the background color is olive */
  @media screen and (max-width: 480px) {
    .subscribe_div_section {
        top: 20px !important;
        width: 85%;
      }
      .subscribe_top_img {
        top: 9.6rem !important;
        left: 13rem !important;
        height: 5rem !important;
      }
      .subscribe_email_field{
        max-width: 14rem;
        left: -2px;
      }
      .subscribe_field{
        top: 4px !important;
        left: 0px;
      }
    
  }

  @media screen and (max-width: 375px) {
    .subscribe_div_section {
        top: 30px !important;
        left: 1.8rem;
      }
      .subscribe_top_img {
        top: 9.6rem !important;
        left: 10rem !important;
        height: 4rem !important;
      }
      .subscribe_email_field{
        max-width: 14rem;
        left: -2px;
      }
      .subscribe_field{
        top: 4px !important;
        left: 0px;
      }
    
  }

  .ln_higt{
    line-height: 1rem;
  }
  .login-cross{
    top:0;
    position: relative;
    left: 90%;
  }
  .footer_bottom_fix{
      bottom: 0%;
      position: absolute;
      width: inherit;
  }