.preview_image:focus{
border: 2px solid skyblue;
}
.preview_image .active {
    border: 2px solid skyblue;
    }
.quantity_number{
    border: none;
}
.checkout-product-img-wrapper {
    position: relative;
}
.checkout-quantity {
    background: #a2a2a2;
    border: 1px solid #a2a2a2;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #fff;
    height: 25px;
    line-height: 22px;
    position: absolute;
    right: -7px;
    text-align: center;
    top: -7px;
    width: 25px;
}
.attribute_css{
    border: 2px solid #0098b8 !important;
    border-radius: 0.4rem;
    font-weight: bold;
}

.attribute_css.active {
    border-radius: 0.4rem;
    background: #0098b8 !important;
    color: white !important;
    font-weight: bold !important;
}