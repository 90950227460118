.daily_best_sales_product_card {

    position:absolute;
    bottom: 3%;
}
.popular_product_card {
    position:absolute;
    bottom: 3%;
}

.price_gap{
    padding-right: 100%;
}

.add_to_cart_gap{
    padding-left: 26%;
}
@media only screen and (max-width: 426px) {
    
.cate_slider_mobile{
 min-height: 142px;
}
}